import { totalUnread } from '@/lib/dataSource/lostApi/chat/conversations/totalUnread';
import useMe, { IUseMe } from '@/lib/hooks/me/useMe';
import {
  Context,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

/** The types of users that can be returned */
export type UserType = 'shelter' | 'user';

/**
 * The type used to create the context
 *
 * @interface
 */
export interface IUserContext extends IUseMe {
  /** If the user has unread messages */
  hasMessage: boolean;
  /** A method for returning the user type */
  userType: UserType | null;
  /** The number of unread messages for the user */
  unreadMessageCount: number;
  /** The fetch state of unread messages count */
  fetchingUnreadMessageCount: boolean;
  /** The logged in state of the user */
  isLoggedIn: boolean;
  /** Whether if the logged in user is a shelter or not */
  isShelter: boolean;
  /** The function to get unread messages for user */
  getUnreadMessagesForUser: () => Promise<void>;
  /** The unreadMessageCount setter */
  setUnreadMessageCount: Dispatch<SetStateAction<number>>;
}

/**
 * The default user context
 *
 * @constant {IUserContext}
 */
export const defaultUserContext: IUserContext = {
  hasMessage: false,
  userType: null,
  isFetchingUser: true,
  isLoggedIn: false,
  isShelter: true,
  unreadMessageCount: 0,
  fetchingUnreadMessageCount: false,
  getUnreadMessagesForUser:
    /** The default function to get unread messages for user */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async () => {},
  setUnreadMessageCount:
    /** The default function to set the unread message count */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
};

/**
 * The UserContext
 *
 * @constant {Context<IUserContext>}
 */
const UserContext: Context<IUserContext> =
  createContext<IUserContext>(defaultUserContext);

/**
 * The interface for the UserProvider props
 *
 * @interface
 */
export interface IUserProvider {
  /** The children wrapped by the provider */
  children: ReactNode;
}

/**
 * The UserContextProvider component.
 *
 * @param {IUserProvider} props - The props for the UserContextProvider.
 * @returns {Element} - The UserContextProvider.
 */
export function UserContextProvider({ children }: IUserProvider): JSX.Element {
  /**
   * The data from the me hook
   *
   * @see useMe
   */
  const { idToken, me, isFetchingUser } = useMe();
  /**
   * The total unread message count
   *
   * @constant {number}
   */
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  /**
   * The fetch state of unread messages count
   *
   * @constant {boolean}
   */
  const [fetchingUnreadMessageCount, setFetchingUnreadMessageCount] =
    useState(true);
  /**
   * If the user has unread messages
   *
   * @constant {boolean}
   */
  const hasMessage = useMemo(
    () => unreadMessageCount > 0,
    [unreadMessageCount]
  );

  /** This will determine the type of user that is currently logged in. */
  const userType = useMemo(() => {
    let userType: UserType | null = null;

    if (me?.awos) {
      userType = 'shelter';
    } else if (me?.user && !me?.awos) {
      userType = 'user';
    }
    return userType;
  }, [me?.awos, me?.user]);

  /**
   * The logged in state of the user We get it by checking if we are able to get
   * a user type from the me object
   *
   * @constant {boolean}
   */
  const isLoggedIn = useMemo(() => {
    return !isFetchingUser && userType !== null;
  }, [isFetchingUser, userType]);

  /**
   * Whether if the logged in user is a shelter or not
   *
   * We explicitly check if the user type is of type 'user' so we do not show
   * user specific options while we determine the user type
   *
   * @constant {boolean}
   */
  const isShelter = useMemo(() => {
    if (userType === 'user') {
      return false;
    }
    return true;
  }, [userType]);

  /**
   * Callback to get the total unread messages for a user
   *
   * @returns {void}
   */
  const getUnreadMessagesForUser = useCallback(async () => {
    if (!me || !idToken || userType === 'shelter')
      return setUnreadMessageCount(0);
    /**
     * @property {string} id - The user id
     * @property {string} idToken - The user idToken
     */
    const {
      user: { id },
    } = me;
    try {
      /**
       * The response from the totalUnread function
       *
       * @property {number} unread - The total unread messages for a user
       */
      const { unread } = await totalUnread({
        authToken: idToken,
        userEntityId: id,
      });

      if (unread !== unreadMessageCount) {
        setUnreadMessageCount(unread);
      }
    } catch (error) {
      console.error('Error fetching unread messages', error);
    } finally {
      setFetchingUnreadMessageCount(false);
    }
  }, [idToken, me, unreadMessageCount, userType]);

  /** Make initial call for unread messages */
  useEffect(() => {
    getUnreadMessagesForUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, me]);

  return (
    <UserContext.Provider
      value={{
        idToken,
        hasMessage,
        me,
        userType,
        isLoggedIn,
        isShelter,
        isFetchingUser,
        getUnreadMessagesForUser,
        unreadMessageCount,
        setUnreadMessageCount,
        fetchingUnreadMessageCount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

/**
 * A hook to access the UserContext
 *
 * @returns {IUserContext} - The UserContext
 */
export const useUserContext = (): IUserContext => useContext(UserContext);
