import { PetcoLoveSDK } from '@petcolove/lost--client--api-sdk';

/**
 * Initialize the SDK
 *
 * @returns {PetcoLoveSDK} The SDK instance
 */
export function initSDK(): PetcoLoveSDK {
  if (!process.env.NEXT_PUBLIC_PLL_API_BASE_URL) {
    throw new Error('NEXT_PUBLIC_PLL_API_BASE_URL is not defined');
  }

  if (!process.env.NEXT_PUBLIC_PLL_WEBSOCKET_BASE_URL) {
    throw new Error('NEXT_PUBLIC_PLL_WEBSOCKET_BASE_URL is not defined');
  }

  const sdk = new PetcoLoveSDK(
    process.env.NEXT_PUBLIC_PLL_API_BASE_URL,
    process.env.NEXT_PUBLIC_PLL_WEBSOCKET_BASE_URL
  );

  return sdk;
}

export const sdk: PetcoLoveSDK = initSDK();
