import AbstractStorage from './abstract-storage';

/**
 * SessionStorage Session Storage is a storage that is available only in the
 * current browser tab. It is cleared when the tab is closed. It is not shared
 * between tabs.
 *
 * @augments AbstractStorage
 * @class
 */
export default class SessionStorage extends AbstractStorage {
  /**
   * Whether the session storage is available.
   *
   * @returns {boolean} - Whether the session storage is available.
   */
  private isAvailable(): boolean {
    return typeof window !== 'undefined';
  }

  /**
   * Get Get the data from the session storage.
   *
   * @param {string} id - The id of the data to get.
   * @returns {object | null} - The data or null if not found.
   */
  get(id: string): object | null {
    if (!this.isAvailable()) {
      return null;
    }
    const data = sessionStorage.getItem(id);

    if (!data) return null;
    // Parse the data and check if the expiration date is passed
    let parsedData = null;
    parsedData = JSON.parse(data);
    if (parsedData.expiration && new Date(parsedData.expiration) < new Date()) {
      this.delete(id);
      return null;
    }
    return parsedData;
  }

  /**
   * Set Set the data in the session storage.
   *
   * @param {string} id - The id of the data to set.
   * @param {object | string} data - The data to set.
   * @param {Date} expiration - The expiration date of the data.
   */
  set(id: string, data: object | string, expiration?: Date): void {
    if (!this.isAvailable()) {
      return;
    }
    if (expiration) {
      if (typeof data === 'string') {
        data = { data, expiration };
      } else {
        data = { ...data, expiration };
      }
    }
    sessionStorage.setItem(id, JSON.stringify(data));
  }

  /**
   * Delete Delete the data from the session storage.
   *
   * @param {string} id - The id of the data to delete.
   */
  delete(id: string): void {
    sessionStorage.removeItem(id);
  }
}
