import { getIdTokenClient } from '@/lib/dataSource/auth/getIdToken';
import getMe from '@/lib/dataSource/lostApi/auth/me';
import { usePersistentBannerContext } from '@/lib/hooks/persistentBanner/PersistentBanner';
import { tomorrow } from '@/lib/utils/helpers/dateHelpers/dates';
import SessionStorage from '@/lib/utils/storage/session-storage';
import { useUser } from '@auth0/nextjs-auth0/client';
import { IMeDto } from '@petcolove/lost--client--api-sdk/dist/concrete/sdks/services/auth/dto';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

declare global {
  // eslint-disable-next-line jsdoc/require-jsdoc
  interface Window {
    // eslint-disable-next-line jsdoc/require-jsdoc
    dataLayer: Array<{ user_id: number } | object>;
  }
}

/**
 * Store Me This is used to deal with the session storage for me object
 *
 * @class MeStorage
 */
export class MeStorage {
  /** The session storage used in the class */
  readonly meStorage = new SessionStorage();

  /** The key to use for the storage */
  readonly meStorageKey = 'me';

  /**
   * A method used to store the me data in session storage
   *
   * @param {IMeDto} me - The me data to store
   */
  public set = (me: IMeDto) => {
    this.meStorage.set(this.meStorageKey, me, tomorrow);
  };

  /** A method used to remove the me data from session storage */
  public delete = () => {
    this.meStorage.delete(this.meStorageKey);
  };

  /**
   * A method used to get the me data from session storage
   *
   * @returns {IMeDto | null} The me data
   */
  public get = (): IMeDto | null => {
    return this.meStorage.get(this.meStorageKey) as IMeDto;
  };
}

/**
 * IUseMe
 *
 * @interface IUseMe
 */
export interface IUseMe {
  /** The me object */
  me?: IMeDto | null;
  /** The idToken */
  idToken?: string;
  /** Indicates if the user is being fetched */
  isFetchingUser: boolean;
}

/** The me storage */
const meStorage = new MeStorage();

/**
 * Hook to get the me object and the idToken
 *
 * @returns {IUseMe} Me - The me object
 */
const useMe = (): IUseMe => {
  const [idToken, setIdToken] = useState<string>();
  const [me, setMe] = useState<IMeDto | null>();
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const { user, isLoading } = useUser();
  const router = useRouter();
  const { type, setBannerData } = usePersistentBannerContext();

  /** Hook to get the idToken */
  useEffect(() => {
    if (!isLoading && user) {
      getIdTokenClient()
        .then((idToken) => {
          setIdToken(idToken);
        })
        .catch((error) => console.error(error));
    } else {
      /** If there is no user, than there should never be a warning banner */
      if (type === 'warning') {
        setBannerData();
      }
      /** Clear the me object in session storage if there is no user */
      meStorage.delete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  /** Hook to clear the isFetchingUser flag when there is no logged in user */
  useEffect(() => {
    if (!isLoading && !user) {
      setIsFetchingUser(false);
    }
  }, [isLoading, user, router]);

  /** Hook to get the me object */
  useEffect(() => {
    if (!idToken) return;

    /** Check if we have the me object in session storage */
    let storedMe = meStorage.get();

    /**
     * Check the email of the me object in session storage and the email of the
     * useUser hook to check for equality if they are not equal it will clear
     * the data in storage
     */
    if (storedMe?.user?.email !== user?.email) {
      /** Clear the me object in session storage */
      meStorage.delete();
      /**
       * Set stored me to null, since there is no need to try to fetch it from
       * storage if we know it has just been cleared
       */
      storedMe = null;
    }

    /**
     * If idToken is available and the me key in session storage is not
     * available it will use getMe call to set the me key in session storage
     */
    if (!storedMe && idToken) {
      getMe(idToken)
        .then((me) => {
          setMe(me);
          meStorage.set(me);
        })
        .catch((error) => {
          console.error(error);
          setMe(null);
          meStorage.delete();
        })
        .finally(() => {
          setIsFetchingUser(false);
        });
    } else {
      /**
       * If there is not need to re-fetch the me object, clear the
       * isFetchingUser flag
       */
      setMe(storedMe);
      setIsFetchingUser(false);
    }
  }, [idToken, user?.email]);

  /** Hook to set the user id in the data layer if available */
  useEffect(() => {
    if (me?.user?.id) {
      // Set dataLayer
      window.dataLayer = window.dataLayer || [];
      // Check if user id is already set
      const userAlreadySet = window.dataLayer.some(
        (el) => 'user_id' in el && el.user_id === me.user.id
      );

      if (!userAlreadySet) {
        window.dataLayer.push({
          user_id: me.user.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return {
    me,
    idToken,
    isFetchingUser,
  };
};

export default useMe;
